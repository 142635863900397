

import {IonContent, IonPage} from '@ionic/vue';
import FixedFooter from "@/components/FixedFooter.vue";
import router from "@/router";
import {FooterButtonsBuilder} from "@/mixins/FooterButtonsBuilder";
import {computed} from "vue";
import {useStore} from "vuex";
import {defineComponent} from "@vue/runtime-core";

export default defineComponent({
    name: 'CreateJourneySetInstructions',
    components: {
        IonContent,
        IonPage,
        FixedFooter
    },
    async ionViewWillEnter() {
        await this.store.dispatch('app/setPageTitle', this.language.journeySetCreation);
    },
    setup() {
        const store = useStore();
        return {
            store,
            language: computed(() => store.getters['app/language']),
            footerButtons: computed(() => {
                return new FooterButtonsBuilder()
                    .addClearBackButton()
                    .addPrimaryNextButton(() => router.push({
                        name: 'journeySetTemplate',
                        params: {journeySetId: 'create'}
                    }))
                    .create();
            })
        }
    }
});
